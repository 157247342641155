<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit ? '编辑菜单' : '新增菜单'" :visible.sync="show" width="600px"
        class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">
            <el-form-item label="菜单名称" prop="">
                <el-input v-model="menuInfo.name" />
            </el-form-item>

            <el-form-item label="图标" prop="">
                <el-input v-model="menuInfo.icon" />
            </el-form-item>

            <el-form-item label="URL地址" prop="">
                <el-input v-model="menuInfo.url" />
            </el-form-item>

            <el-form-item label="router地址" prop="">
                <el-input v-model="menuInfo.route" />
            </el-form-item>

            <el-form-item label="参数" prop="">
                <el-input v-model="menuInfo.param" />
            </el-form-item>

            <!-- pid: "", //  上级ID -->
            <el-form-item v-show="JSON.stringify(higher).length > 2" label="上级" prop="">
                <el-input :value="higher.name" disabled />
            </el-form-item>

            <!-- type: "",//类型： 1 模块 2 导航 3 菜单 4 节点 -->
            <el-form-item label="类型" prop="">
                <el-select v-model="menuInfo.type" placeholder="请选择类型">
                    <el-option :label="'无'" :value="0"></el-option>
                    <el-option :label="'模块'" :value="1"></el-option>
                    <el-option :label="'导航'" :value="2"></el-option>
                    <el-option :label="'菜单'" :value="3"></el-option>
                    <el-option :label="'节点'" :value="4"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="权限标识" prop="">
                <el-input v-model="menuInfo.permission" />
            </el-form-item>

            <!-- //   是否显示： 1 显示 2 不显示 -->
            <el-form-item label="是否显示" prop="">
                <el-switch v-model="menuInfo.status" :active-value="1" active-color="#5BD995" active-text="显示"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="隐藏" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <!-- //   是否公共： 1 是 2 否 -->
            <!-- <el-form-item label="是否公共" prop="">
                <el-switch v-model="menuInfo.is_public" :active-value="1" active-color="#5BD995" active-text="是"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                </el-switch>
            </el-form-item> -->

            <el-form-item label="显示顺序" prop="name1">
                <el-input v-model="menuInfo.sort" />
            </el-form-item>

            <el-form-item label="菜单备注" prop="">
                <el-input v-model="menuInfo.note" />
            </el-form-item>
        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',

                menuInfo: {
                    name: '', //菜单名称
                    icon: '', //图标
                    url: '', //URL地址
                    route: '', //URL地址
                    param: '', //   参数
                    pid: '', //  上级ID
                    type: '', //类型： 1 模块 2 导航 3 菜单 4 节点
                    permission: '', //  权限标识
                    status: 1, //   是否显示： 1 显示 2 不显示
                    // is_public: "", //是否公共： 1 是 2 否
                    note: '', // 菜单备注
                    sort: '' // 显示顺序
                },

                higher: {},

                isEdit: false
            }
        },

        computed: {},

        mounted() {},

        created() {},

        watch: {
            show(value) {
                if (this.show) {} else {
                    this.isEdit = false

                    this.higher = {}

                    this.menuInfo = {
                        name: '', //菜单名称
                        icon: '', //图标
                        url: '', //URL地址
                        route: '', //URL地址
                        param: '', //   参数
                        pid: '', //  上级ID
                        type: '', //类型： 1 模块 2 导航 3 菜单 4 节点
                        permission: '', //  权限标识
                        status: 1, //   是否显示： 1 显示 2 不显示
                        // is_public: "", //是否公共： 1 是 2 否
                        note: '', // 菜单备注
                        sort: '' // 显示顺序
                    }
                }
            }
        },

        methods: {
            open() {
                this.formShow = true
                this.show = true
            },

            openChild(row) {
                this.higher = row
                this.menuInfo.pid = row.id

                this.open()
            },

            edit(row) {
                this.isEdit = true

                // this.menuInfo = {
                //     ...row ======================
                // }

                this.menuInfo = row

                console.log('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW', this.menuInfo)
                this.open()

                this.$http
                    .get('/api/menu/' + row.pid)
                    .then((res) => {
                        if (res.code == 200) {
                            this.higher = res.data
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            refresh() {
                this.show = false
                this.$emit('refresh') //=================
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            save() {
                console.log(this.menuInfo)

                if (this.isEdit) {
                    this.$http
                        .put('/api/menu/' + this.menuInfo.id, this.menuInfo)
                        .then((res) => {
                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }
                            if (res.code == 200) {
                                this.refresh()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    this.$http
                        .post('/api/menu', this.menuInfo)
                        .then((res) => {
                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }
                            if (res.code == 200) {
                                this.refresh()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
        }
    }
</script>

<style>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>